<template>
    <div class="page" id="authorityManagement">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="query-form">
            <el-form-item label-width="0">
                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-input
                                placeholder="请输入关键词"
                                size="small"
                                v-model="ruleForm.keyword"
                                clearable>
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-select style="width: 100%" size="small" v-model="ruleForm.roleId" placeholder="下拉选择角色">
                            <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.roleName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="2">
                        <el-button @click="search" size="small" type="primary">搜索</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label-width="0">
                <el-row :gutter="10">
                    <el-col :span="4">
                        <div class="tip">tip:(昵称/手机号码、操作人)</div>
                    </el-col>
                    <el-col :span="4" :offset="15">
                        <div class="manageButton">
                            <el-button @click="newlyAdded(1)" size="small" type="primary">新增</el-button>
                            <el-button @click="management" size="small" type="primary">角色管理</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form-item>


            <!--            新增H5管理员-->
            <el-dialog
                    :title="newTitle"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :close-on-click-modal="tanchuan"
                    :before-close="handleClose">
                <el-form-item label="角色选择" prop="jusexuanze">
                    <el-col :span="20">
                        <el-select style="width: 100%" size="small" v-model="ruleForm.jusexuanze" placeholder="下拉选择角色">
                            <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.roleName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="权限手机号" prop="phone">
                    <el-col :span="20">
                        <el-input
                                placeholder="请输入手机号"
                                size="small"
                                :disabled="disablePhone"
                                v-model="ruleForm.phone"
                                clearable>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="10">
                        <el-button size="small" type="primary" @click="submitForm('ruleForm')">确定</el-button>
                    </el-col>
                    <el-col :span="10">
                        <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
                    </el-col>
                </el-form-item>
            </el-dialog>

            <!--            角色管理-->
            <el-dialog
                    title="角色管理"
                    :visible.sync="roleManagement"
                    width="50%"
                    :close-on-click-modal="tanchuan"
                    :before-close="managementHandleClose">
                <el-col :span="4" :offset="18">
                    <el-button @click="addaddRole(0)" size="small" type="primary">新增角色</el-button>
                </el-col>
                <el-table
                        :data="managementList"
                        v-loading="managementLoading"
                        size="small"
                        height="400px"
                        class="table">
                    <el-table-column prop="roleName" label="角色名称" width="200" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="permission" label="角色标识" width="200">
                    </el-table-column>
                    <el-table-column prop="openState" label="启用情况" width="100">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.openState"
                                       :disabled='true'
                                       :active-value="'0'"
                                       :inactive-value="'1'"
                            ></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    v-if="hasPermission('activity:activityList:edit')"
                                    icon="el-icon-edit" type="text"
                                    size="mini" @click="addaddRole(1,scope.row)">编辑
                            </el-button>
                            <el-button
                                    v-if="hasPermission('activity:activityList:delete')"
                                    icon="el-icon-delete" type="text"
                                    size="mini" @click="deleteaddRole(scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        :current-page="managementPageNo"
                        :page-size="managementPageSize"
                        :page-sizes="[10, 20, 50, 100]"
                        :total="managementTotal"
                        @size-change="managementSizeChangeHandle"
                        @current-change="managementCurrentChangeHandle"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </el-dialog>

            <!--            新增角色-->
            <el-dialog
                    :title="addRoleTitle"
                    :visible.sync="addRole"
                    width="30%"
                    :close-on-click-modal="tanchuan"
                    :before-close="addRoleHandleClose">
                <el-form-item label="角色标识" prop="permission">
                    <el-col :span="20">
                        <el-input
                                placeholder="请输入角色标识"
                                size="small"
                                v-model="ruleForm.permission"
                                clearable>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="角色名称" prop="roleName">
                    <el-col :span="20">
                        <el-input
                                placeholder="请输入角色名称"
                                size="small"
                                v-model="ruleForm.roleName"
                                clearable>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="是否启用" prop="openState">
                    <el-col :span="20">
                        <el-switch v-model="ruleForm.openState"
                                   active-color="#13ce66"
                                   inactive-color="#ff4949"
                                   :active-value="'0'"
                                   :inactive-value="'1'"
                        ></el-switch>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="10">
                        <el-button size="small" type="primary" @click="addRoleSubmitForm('ruleForm')">确定</el-button>
                    </el-col>
                    <el-col :span="10">
                        <el-button size="small" @click="addRoleResetForm('ruleForm')">取消</el-button>
                    </el-col>
                </el-form-item>
            </el-dialog>


        </el-form>
        <div class="bg-white top">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 60px)"
                    class="table">
                <el-table-column prop="nickname" label="账号昵称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="roleName" label="角色" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="phone" label="手机号码" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="createUser" label="操作人" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="createTime" label="操作时间" width="300">
                </el-table-column>
                <el-table-column fixed="right" width="200" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                v-if="hasPermission('activity:activityList:edit')"
                                icon="el-icon-edit" type="text"
                                size="mini" @click="newlyAdded(2,scope.row)">编辑
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:activityList:delete')"
                                icon="el-icon-delete" type="text"
                                size="mini" @click="deleassociated(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    :current-page="pageNo"
                    :page-size="pageSize"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="total"
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "authorityManagement",
        data() {
            return {
                loading: false,//主表的加载
                dataList: [],//主播的数据
                pageNo: 1,
                pageSize: 10,
                total: 0,

                tanchuan: false,

                //新增H5管理员弹窗
                newTitle: '',//标题
                editId: '',//编辑id
                dialogVisible: false,
                disablePhone: false,//是否禁止手机框的输入

                //角色管理弹窗
                roleManagement: false,
                managementList: [],//角色管理列表
                managementLoading: false,//列表加载
                managementPageNo: 1,
                managementPageSize: 10,
                managementTotal: 0,

                //新增角色弹窗
                addRoleTitle: '',//新增角色标题
                addRole: false,
                addRoleId: '',//角色id


                ruleForm: {
                    keyword: '',//关键词
                    roleId: '',//角色id

                    jusexuanze: '',//新增角色选择id
                    phone: '',//权限手机号

                    permission: '',//角色标识
                    roleName: '',//角色名称
                    openState: '1',//是否启用
                },
                options: [],
                rules: {
                    jusexuanze: [
                        {required: true, message: '请选择角色', trigger: 'change'}
                    ],
                    phone: [
                        {required: true, pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号', trigger: 'blur'}
                    ],
                    permission: [
                        {required: true, message: '请输入角色标识', trigger: 'blur'}
                    ],
                    roleName: [
                        {required: true, message: '请输入角色名称', trigger: 'blur'}
                    ],
                },
            }
        },
        mounted() {
            this.allRoles();
            this.userAssociationList();
        },
        methods: {
            //所有角色
            allRoles() {
                this.$axios(this.api.authority.allRoles, {}, 'get').then((res) => {
                    if (res.status) {
                        this.options = res.data;
                    }
                })
            },

            //关联列表删除
            deleassociated(row) {
                this.$confirm(`确定删除所选项吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.authority.deleassociated + row.id, {}, 'delete').then((res) => {
                        console.log(res)
                        if (res.status) {
                            this.$message.success('删除成功')
                            this.userAssociationList();
                        }
                    })
                });
            },

            //用户关联列表
            userAssociationList() {
                this.loading = true;
                this.$axios(this.api.authority.userAssociationList, {
                    current: this.pageNo,
                    size: this.pageSize,
                    keyword: this.ruleForm.keyword,
                    roleId: this.ruleForm.roleId,
                }, 'get').then((res) => {
                    if (res.status) {
                        this.loading = false;
                        this.dataList = res.data.records;
                        this.total = parseInt(res.data.total)
                    }
                })
            },

            //搜素
            search() {
                this.userAssociationList();
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.userAssociationList();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.userAssociationList();
            },


            //新增H5角色管理
            //点击关闭按钮
            handleClose(done) {
                this.dialogVisible = false;
                this.qingkong();
            },

            //点击新增, 1为新增，2为编辑
            newlyAdded(num, row) {
                console.log(row)
                if (num == 1) {
                    this.newTitle = '新增H5管理员'
                } else {
                    this.newTitle = '编辑H5管理员'
                    this.ruleForm.phone = row.phone;
                    this.ruleForm.jusexuanze = row.roleId;
                    this.editId = row.id;
                    this.disablePhone = true;
                }
                this.dialogVisible = true;
            },

            //确定
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.newTitle == '新增H5管理员') {
                            this.$axios(this.api.authority.newlyAdded, {
                                baseRoleId: this.ruleForm.jusexuanze,
                                baseUserPhone: this.ruleForm.phone,
                            }, 'post').then((res) => {
                                if (res.status) {
                                    this.$message.success('新增成功')
                                    this.userAssociationList();
                                    this.dialogVisible = false;
                                    this.qingkong();
                                }
                            })
                        } else {
                            this.$axios(this.api.authority.addRole, {
                                baseRoleId: this.ruleForm.jusexuanze,
                                baseUserPhone: this.ruleForm.phone,
                                id: this.editId,
                            }, 'put').then((res) => {
                                this.$message.success('修改成功')
                                this.userAssociationList();
                                this.dialogVisible = false;
                                this.qingkong();
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //清空弹窗数据
            qingkong() {
                this.ruleForm.jusexuanze = '';
                this.ruleForm.phone = '';
            },

            //取消
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
            },


            //角色管理按钮
            management() {
                this.managementLoading = true;
                this.$axios(this.api.authority.roleList, {}, 'get').then((res) => {
                    if (res.status) {
                        this.managementList = res.data.records;
                        this.managementTotal = parseInt(res.data.total);
                        this.managementLoading = false;
                    }
                })
                this.roleManagement = true;
            },

            // 每页数
            managementSizeChangeHandle(val) {
                this.managementPageSize = val;
                this.managementPageNo = 1;
                this.management();
            },

            // 当前页
            managementCurrentChangeHandle(val) {
                this.managementPageNo = val
                this.management();
            },

            //弹窗关闭按钮
            managementHandleClose(done) {
                this.roleManagement = false;
            },
            //新增角色,num 0新增，1编辑
            addaddRole(num, row) {
                if (num == 0) {
                    this.addRoleTitle = '新增角色';
                } else {
                    this.addRoleTitle = '编辑角色';
                    this.ruleForm.openState = row.openState;
                    this.ruleForm.permission = row.permission;
                    this.ruleForm.roleName = row.roleName;
                    this.addRoleId = row.id;
                }
                this.addRole = true;
            },


            //新增角色弹窗
            //弹窗关闭按钮
            addRoleHandleClose(done) {
                this.addRole = false;
            },

            //确定
            addRoleSubmitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addRoleTitle == '新增角色') {
                            this.$axios(this.api.authority.addRole, {
                                permission: this.ruleForm.permission,
                                roleName: this.ruleForm.roleName,
                                openState: this.ruleForm.openState,
                            }, 'post').then((res) => {
                                if (res.status) {
                                    this.$message.success('新增成功')
                                    this.management();
                                    this.addRole = false;
                                    this.addRoleQingkong();
                                }
                            })
                        } else {
                            this.$axios(this.api.authority.modifyRole, {
                                id: this.addRoleId,
                                openState: this.ruleForm.openState,
                                permission: this.ruleForm.permission,
                                roleName: this.ruleForm.roleName,
                            }, 'put').then((res) => {
                                this.$message.success('修改成功')
                                this.management();
                                this.addRole = false;
                                this.addRoleQingkong();
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //删除角色
            deleteaddRole(row) {
                console.log(row)
                this.$confirm(`确定删除所选项吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.authority.deleteRole + row.id, {}, 'delete').then((res) => {
                        if (res.status) {
                            this.$message.success('删除成功')
                            this.management();
                        }
                    })
                });
            },

            //新增角色清空
            addRoleQingkong() {
                this.ruleForm.permission = '';
                this.ruleForm.roleName = '';
                this.ruleForm.openState = '1';
            },

            //取消
            addRoleResetForm(formName) {
                this.$refs[formName].resetFields();
                this.addRole = false
            },

        },
    }
</script>

<style scoped>
    .top{
        height: calc(100% - 150px);
    }
</style>
